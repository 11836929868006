/* global google */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GMaps from '../../components/GMaps';

import {
    GMAPS_MARKER_SHOW_LOADER_THRESHOLD,
    GMAPS_USA_DEFAULT_LOCATION,
    GMAPS_USA_DEFAULT_ZOOM
} from '../../constants';
import Search from '../../components/Search';
import FilterDropDown from '../../components/FilterDropDown';
import SavedLocationsList from '../../components/SavedLocationsList';
import GMapsSearchBox from '../../components/GMaps/SearchBox';
import RefreshModal from '../../components/RefreshModal';
import ClearLocationModal from '../../components/ClearLocationModal';

import { getLocationDetails } from '../../apis/RepoService';
import {
    clearAllLocationsFromStorage, clearSingleLocationFromStorage,
    getAllLocations,
    saveLocationInStorage, updateLocationInStorage
} from '../../utils/saveLocationUtils';
import MarkerClusterer from '../../utils/markerClusterer';

import loader from '../../assets/images/load.gif';

const mapPanZoomDefault = 15;

class PublicMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            mapCenter: GMAPS_USA_DEFAULT_LOCATION,
            mapZoom: GMAPS_USA_DEFAULT_ZOOM,
            isFetchingMarkerData: false,
            selectedMarker: null,
            selectedMarkerData: {},
            showFilterDropDown: false,
            showSavedLocations: false,
            savedLocations: [],
            isLocationSaved: false,
            statusCount: {},
            typeCount: {},
            showRefreshModal: false,
            showClearLocationModal: false,
            showMapLoader: true,
            isFetchingMarkers: false,
        };

        this.mapRef = null;
        this.mapClustererRef = null;
        this.markerRefs = [];
        this.locations = [];
        this.mapSearchRef = null;
        this.selectedSavedLocation = {};
        this.clusteringBeginListener = null;
        this.clusteringEndListener = null;

        this.mapsLoadPromised();
    }

    componentDidMount() {
        const { isFacilitiesActive } = this.props;

        this.setFilterValue(isFacilitiesActive);
        this.updateMapLoader(true);
        this.updateMarkersLoadingState(true);

        this.initializeMapData()
            .then((resArr) => {
                this.updateMarkersLoadingState(false);
                this.updateLocations(resArr[0].parsedData);
                this.setTypeCount(resArr[0].typeCount);
                this.setStatusCount(resArr[0].statusCount);
                this.getLocationFromStorage(isFacilitiesActive);

                this.updateMapLoader(false);
            })
            .catch(err=>{
                this.updateMapLoader(false);
            })
    }

    componentWillUnmount() {
        this.detachClusteringListeners();
    }

    mapsLoadPromised = () => {
        this.mapLoadPromiseResolver = null;
        this.mapLoadPromise = new Promise((resolve) => {
            this.mapLoadPromiseResolver = resolve;
        });
    };

    initializeMapData = () => {
        const { getLocations } = this.props;

        return Promise.all([
            getLocations(),
            this.mapLoadPromise
        ])
    };

    updateMapLoader = (showMapLoader, callback) => {
        this.setState({ showMapLoader }, callback);
    };

    setFilterValue = (facility) => {
        if(facility) {
            this.updateFilter('facility');
        } else {
            this.updateFilter('county');
        }
    };

    updateShowRefreshModal = (state = false) => {
        this.setState({showRefreshModal: state});
    };

    updateShowClearLocationModal = (state = false) => {
        this.setState({showClearLocationModal: state});
    };

    updateFilter = (filter) => {
        this.setState({ filter: filter});
    };

    updateLocations = (locations) => {
        this.locations = locations;
        this.drawMapData(locations);
    };

    updateStatusCount = (statusCount) => {
        this.setState({statusCount: statusCount})
    };

    setStatusCount = (statusCount) => {
        this.updateStatusCount(statusCount);
    };

    updateTypeCount = (typeCount) => {
        this.setState({typeCount: typeCount});
    };

    setTypeCount = (typeCount) => {
        this.updateTypeCount(typeCount);
    };

    drawMapData = (locations) => {
        this.markerRefs = this.createMarkers(locations);
        this.clusterMarkers(this.markerRefs);
    };

    createMarkers = (dataset) => {
        const { markerIcons } = this.props;
        const markers = dataset.map(({latLng, status, type}) => {
            return new google.maps.Marker(
                {
                    position: latLng,
                    icon: markerIcons[status],
                    type,
                    status
                }
            );
        });

        markers.forEach((marker, index) => {
            marker.addListener('click', () => {
                this.onMarkerClick(index);
            });
        });

        return markers;
    };

    clusterMarkers = (markers) => {
        this.mapClustererRef = MarkerClusterer(this.mapRef, markers);

        this.detachClusteringListeners();
        this.attachClusteringListeners();
    };

    attachClusteringListeners = () => {

        this.clusteringBeginListener = this.mapClustererRef.addListener('clusteringbegin', (event) => {
            const { showMapLoader } = this.state;
            if (!showMapLoader) {
                this.updateMapLoader(true);
            }
        });
        this.clusteringEndListener = this.mapClustererRef.addListener('clusteringend', (event) => {
            const { showMapLoader } = this.state;
            if (showMapLoader) {
                this.updateMapLoader(false);
            }
        });
    };

    detachClusteringListeners = () => {
        if (this.clusteringBeginListener) {
            this.clusteringBeginListener.remove();
        }

        if (this.clusteringEndListener) {
            this.clusteringEndListener.remove();
        }
    };

    updateMarkerData = (data) => {
        this.setState({selectedMarkerData: data});
    };

    updateMarkerFetchingState = (state = false) => {
        this.setState({isFetchingMarkerData: state});
    };

    updateMarkersLoadingState = (boolState) => {
        this.setState({isFetchingMarkers: boolState});
    };

    updateMapCenter = (gMapLocation) => {
        this.setState({ mapCenter: gMapLocation });
    };

    updateMapZoom = (zoom) => {
        this.setState({ mapZoom: zoom });
    };

    focusMap = (gMapLocation, zoom = mapPanZoomDefault) => {
        this.updateMapCenter(gMapLocation);
        this.updateMapZoom(zoom);
    };

    updateSelectedMarkerRef = (index, ref) => {
        this.setState({
            selectedMarker: { index, ref }
        });
    };

    updateIsLocationSaved = (state) => {
        this.setState({ isLocationSaved: state});
    };

    resetIsLocationSaved = () => {
        this.setState({ isLocationSaved: false});
    };

    resetSelectedMarker = () => {
        this.setState({
            selectedMarker: null
        });
    };

    resetSelectedMarkerData = () => {
        this.setState({ selectedMarkerData: {}});
    };

    onMarkerClick = (index) => {
        const ref = this.markerRefs[index];
        const { id, latLng } = this.getMarkerData(index);
        const { isFetchingMarkerData, filter } = this.state;

        if(!isFetchingMarkerData) {
            this.getLocationDetails(id)
                .then((response) => {
                    const { data: { data } } = response;
                    this.updateMarkerData(data);
                    this.updateMarkerFetchingState();
                    updateLocationInStorage(filter, data);
                    this.updateLocationInState(data);
                    this.popInfoWindow(index, ref, latLng);
                    this.updateMapCenter(ref.position)
                })
                .catch(error => {
                    const { status, message } = error;

                    if(status === 400 && message === 'Id is invalid.') {
                        this.updateShowRefreshModal(true);
                        this.updateMarkerFetchingState();
                    }
                })
        }
    };

    popInfoWindow = (index, ref, gMapLocation) => {
        this.closeAllDropDowns();
        this.toggleIsLocationSaved(gMapLocation);
        this.updateSelectedMarkerRef(index, ref);
    };

    isLocationSaved = (locations, gMapLocation) => {
        return locations.find(data => parseFloat(data.latitude) === parseFloat(gMapLocation.lat)
            && parseFloat(data.longitude) === parseFloat(gMapLocation.lng));
    };

    toggleIsLocationSaved = (gMapLocation) => {
        const { savedLocations } = this.state;
        const isSaved = this.isLocationSaved(savedLocations, gMapLocation);

        if(isSaved) {
            this.updateIsLocationSaved(true);
        } else {
            this.resetIsLocationSaved();
        }
    };

    onInfoWindowClose = () => {
        this.resetSelectedMarkerData();
        this.resetSelectedMarker();
    };

    onSearchLoad = (autoComplete) => {
        this.mapSearchRef = autoComplete;
    };

    onPlacesChanged = () => {
        if (this.mapSearchRef !== null) {
            let getPlace = this.mapSearchRef.getPlace()
            let query = getPlace.geometry ? getPlace.geometry.location : null
            if (query){
                this.focusMap(query);
            } 
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    };

    getLocationDetails = (id) => {
        const { filter, selectedMarker } = this.state;

        if(!selectedMarker) {
            this.updateMarkerFetchingState(true);
        }

        return getLocationDetails(id, filter).then((response) => {
            return Promise.resolve(response);
        });
    };

    getMarkerData = (index) => {
        return this.locations[index];
    };

    toggleSavedLocationsList = (state = false) => {
        this.setState({
            showSavedLocations: state
        });
    };

    toggleFilterDropDown = (state = false) => {
        this.setState({
            showFilterDropDown: state
        });
    };

    closeAllDropDowns = () => {
        this.toggleSavedLocationsList();
        this.toggleFilterDropDown();
        this.resetSelectedMarker();
    };

    handleClick = (func, state) => {
        this.closeAllDropDowns();
        if(state) {
            func(state);
        }
    };

    updateSavedLocations = (locations) => {
        this.setState({savedLocations: locations});
    };

    saveLocation = (location) => {
        const { savedLocations } = this.state;
        const isLocationSaved = savedLocations.find(data => parseFloat(data.latitude) === parseFloat(location.latitude)
            && parseFloat(data.longitude) === parseFloat(location.longitude));

        if(!isLocationSaved) {
            let newSavedLocations = [...savedLocations];
            newSavedLocations.push(location);
            this.setSavedLocations(newSavedLocations);
            this.saveLocationInStorage(location);
            this.updateIsLocationSaved(true);
        }
    };

    saveLocationInStorage = (location) => {
        const { filter } = this.state;
        saveLocationInStorage(filter, location);
    };

    getLocationFromStorage = (facility) => {
        if(facility) {
            this.setSavedLocations(getAllLocations('facility'));
        } else {
            this.setSavedLocations(getAllLocations('county'));
        }
    };

    setSavedLocations = (locations = []) => {
        this.updateSavedLocations(locations);
    };

    onSavedLocationClick = (gMapLocation, index) => {
        const selectedLocation = this.locations.filter(elem => parseFloat(elem.latitude) === gMapLocation.lat
            && parseFloat(elem.longitude) === gMapLocation.lng);

        if(selectedLocation.length) {
            this.focusMap(gMapLocation);
        } else {
            this.updateShowClearLocationModal(true);
            this.selectedSavedLocation = {gMapLocation, index};
        }
    };

    clearLocationFromModal = () => {
        const { gMapLocation, index } = this.selectedSavedLocation;
        this.clearSingleLocation(gMapLocation, index);
        this.selectedSavedLocation = {};
        this.updateShowClearLocationModal();
    };

    clearAllSavedLocations = () => {
        const { filter } = this.state;
        clearAllLocationsFromStorage(filter);
        this.setSavedLocations();
        this.toggleSavedLocationsList();
    };

    clearSingleLocation = (gMapLocation, index) => {
        const { filter } = this.state;
        clearSingleLocationFromStorage(filter, gMapLocation);
        this.clearSingleLocationFromState(index);
    };

    clearSingleLocationFromState = (index) => {
        const { savedLocations } = this.state;
        let newSavedLocations = [...savedLocations];

        newSavedLocations.splice(index, 1);

        if(newSavedLocations.length) {
            this.setSavedLocations(newSavedLocations);
        } else {
            this.setSavedLocations();
            this.toggleSavedLocationsList();
        }
    };

    updateLocationInState = (location) => {
        const { savedLocations } = this.state;
        let newSavedLocations = [...savedLocations];

        const index = newSavedLocations.findIndex(loc => parseFloat(loc.latitude) === parseFloat(location.latitude)
            && parseFloat(loc.longitude) === parseFloat(location.longitude));

        if(index !== -1) {
            newSavedLocations.splice(index, 1, location);
            this.setSavedLocations(newSavedLocations);
        }
    };

    onGMapsLoad = (map) => {
        this.mapRef = map;
        this.mapLoadPromiseResolver();
    };

    onGMapsZoomChanged = () => {
        const currentZoom = this.mapRef.getZoom();
        this.updateMapZoom(currentZoom);
    };

    onSearchChange = (event) => {
        this.closeAllDropDowns();
    };

    onFilterChange = (filterDropdownState) => {
        this.updateMapLoader(true, () => {
            this.filterLocations(filterDropdownState);
        });
    };

    filterLocations = (filterDropdownState) => {
        const { statusCount } = this.props;
        const newStatusCount = {...statusCount};
        const filteredOptions = [];

        Object.keys(filterDropdownState).forEach(key => {
            if(filterDropdownState[key]) {
                filteredOptions.push(key)
            }
        });

        this.markerRefs.forEach(marker => {
            if(filteredOptions.includes(marker.type) && filteredOptions.includes(marker.status)) {
                marker.setVisible(true);
                newStatusCount[marker.status]++;
            } else {
                marker.setVisible(false);
            }
        });

        this.updateStatusCount(newStatusCount);

        // Helps map loader to kick in and render first
        setTimeout(() => {
            this.redrawCluster();
        }, 100);
    };

    redrawCluster = () => {
        this.mapClustererRef.repaint();
    };

    canShowMapLoader = () => {
        const { showMapLoader, isFetchingMarkers } = this.state;

        return (showMapLoader && isFetchingMarkers) || (showMapLoader && this.markerRefs.length >= GMAPS_MARKER_SHOW_LOADER_THRESHOLD);
    };


    render() {
        const {
            mapCenter, mapZoom, selectedMarker, showFilterDropDown, showSavedLocations, selectedMarkerData,
            isFetchingMarkerData, savedLocations, isLocationSaved, typeCount, statusCount, showRefreshModal,
            showClearLocationModal
        } = this.state;

        const { id, InfoWindowContent, isFacilitiesActive, mapStatuses } = this.props;

        return (
            <div className="map">
                {
                    this.canShowMapLoader() && (
                        <div className="loader-wrapper">
                            <div>
                                <img src={loader} alt="map_loader" />
                            </div>
                        </div>
                    )
                }

                <GMaps center={mapCenter} zoom={mapZoom} id={id} onLoad={this.onGMapsLoad} onZoomChanged={this.onGMapsZoomChanged} >
                    <RefreshModal show={showRefreshModal} />
                    <ClearLocationModal
                        show={showClearLocationModal}
                        clearSingleLocation={this.clearLocationFromModal}
                        onHide={this.updateShowClearLocationModal}
                    />
                    <div className="dropdown-wrapper">
                        <GMapsSearchBox onPlacesChanged={this.onPlacesChanged} onLoad={this.onSearchLoad} >
                            <Search onChange={this.onSearchChange} />
                        </GMapsSearchBox>
                        <div className="other-icons">
                        <span
                            className={`icon-star ${savedLocations.length ? 'active-clr' : 'disabled'}
                            ${showSavedLocations ? 'active' : ''}`}
                            onClick={() => this.handleClick(this.toggleSavedLocationsList, !showSavedLocations)}/>
                            <span
                                className={`icon-filter-active ${showFilterDropDown ? 'active' : ''}`}
                                onClick={() => this.handleClick(this.toggleFilterDropDown, !showFilterDropDown)}/>
                        </div>
                        <div className={`${showFilterDropDown ? '' : 'd-none'}`}>
                            <FilterDropDown isFacilitiesActive={isFacilitiesActive}
                                            statusCount={statusCount}
                                            typeCount={typeCount}
                                            mapStatuses={mapStatuses}
                                            onFilterChange={this.onFilterChange}
                            />
                        </div>
                        {
                            showSavedLocations &&
                            <SavedLocationsList locations={savedLocations}
                                                clearAllSavedLocations={this.clearAllSavedLocations}
                                                clearSingleLocation={this.clearSingleLocation}
                                                onLocationClick={this.onSavedLocationClick}/>
                        }
                        {
                            selectedMarker && !isFetchingMarkerData
                            && <InfoWindowContent data={selectedMarkerData}
                                                  onCloseClick={ this.onInfoWindowClose }
                                                  onSaveLocation={ this.saveLocation }
                                                  isLocationSaved={isLocationSaved}
                            />
                        }
                    </div>
                </GMaps>
            </div>
        );
    }
}

PublicMap.propTypes = {
    getLocations: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    markerIcons: PropTypes.object.isRequired,
    InfoWindowContent: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func
    ]).isRequired,
};

export default PublicMap;
