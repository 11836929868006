import greenMarker from './assets/images/greenMarker.png';
import yellowMarker from './assets/images/yellowMarker.png';
import whiteMarker from './assets/images/whiteMarker.png';
import maroonMarker from './assets/images/maroonMarker.png';
import orangeMarker from './assets/images/orangeMarker.png';

export const MAX_UPLOAD_SIZE = 15728640; // 15MB
export const ACCEPTABLE_FILE_FORMATS = ['.csv', '.xls', '.xlsx', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY;
export const GMAPS_USA_DEFAULT_LOCATION = {lat: 39.828127, lng: -98.579404};
export const GMAPS_USA_DEFAULT_ZOOM = 4;
export const GMAPS_MARKER_CLUSTERER_IMAGE= `${process.env.PUBLIC_URL}/clusterer_images/m`;
export const GMAPS_MARKER_SHOW_LOADER_THRESHOLD= 50000;

export const GMAPS_FACILITY_MARKERS = {
    'open': greenMarker,
    'unknown': yellowMarker,
    'not participating': whiteMarker,
    'closed': maroonMarker
};
export const GMAPS_COUNTY_MARKERS = {
    'county with > 90% pharmacies open': greenMarker,
    'county with 75% - 90% pharmacies open': yellowMarker,
    'county with 50% - 74% pharmacies open': orangeMarker,
    'county with < 50% pharmacies open': maroonMarker
};

export const SAVED_LOCATIONS_STATUS_CLASSES = {
    'open': 'green-spot',
    'unknown': 'yellow-spot',
    'not participating': 'white-spot',
    'closed': 'red-spot',
    'county with > 90% pharmacies open': 'green-spot',
    'county with 75% - 90% pharmacies open': 'yellow-spot',
    'county with 50% - 74% pharmacies open': 'orange-spot',
    'county with < 50% pharmacies open': 'red-spot'
};

export const FILTER_LOCATIONS_STATUS_CLASSES = {
    'open': 'green',
    'unknown': 'yellow',
    'not participating': 'white',
    'closed': 'red',
    'county with > 90% pharmacies open': 'green',
    'county with 75% - 90% pharmacies open': 'yellow',
    'county with 50% - 74% pharmacies open': 'orange',
    'county with < 50% pharmacies open': 'red'
};

export const FACILITY_MAP_STATUSES = {
    'open': 'Open',
    'unknown': 'Unknown',
    'not participating': 'Not Participating',
    'closed': 'Closed'
};
export const COUNTY_MAP_STATUSES = {
    'county with > 90% pharmacies open': '> 90% Facilities Open',
    'county with 75% - 90% pharmacies open': '75% - 90% Facilities Open',
    'county with 50% - 74% pharmacies open': '50% - 74% Facilities Open',
    'county with < 50% pharmacies open': '< 50% Facilities Open'
};

export const FACILITY_STATUS_COUNT = {
    'open': 0,
    'unknown': 0,
    'not participating': 0,
    'closed': 0
};

export const COUNTY_STATUS_COUNT = {
    'county with > 90% pharmacies open': 0,
    'county with 75% - 90% pharmacies open': 0,
    'county with 50% - 74% pharmacies open': 0,
    'county with < 50% pharmacies open': 0
};

export const menu = [
    {
        'id': 'maps',
        'name': 'Maps',
        'rights': ['superAdmin', 'admin', 'partner'],
        'link': '/maps'
    }, {
        'id': 'update-map',
        'name': 'Update Map',
        'rights': ['superAdmin', 'admin'],
        'link': '/update-map'
    }, {
        'id': 'archives',
        'name': 'Archives',
        'rights': ['superAdmin', 'admin'],
        'link': '/archives'
    }, {
        'id': 'user-management',
        'name': 'User Management',
        'rights': ['superAdmin', 'admin'],
        'link': '/user-management'
    }, {
        'id': 'changePassword',
        'name': 'Change Password',
        'rights': ['superAdmin', 'admin', 'partner'],
        'link': '/change-password'
    }
];
